.org_price
{
	display:none;
}

.product_options
{
	padding-bottom: 15px;
	display: flex;
	align-items: flex-end;
}

.product_price
{
	font-weight:bold;
	font-size: 125%;
	min-width: 100px;
	line-height: 34px;
}

.product_basket {
	text-align: center;
}

.product_description h2
{
	margin-top:0;
	margin-bottom: 10px;
}

.category 
{
	margin-bottom:20px;
}

.product
{
	padding-bottom:40px;
	margin-bottom: 10px;
}
.category_header h1
{	
	margin-bottom: 4px;
	font-weight: 700;
	text-transform: uppercase;
	color: inherit;
	padding-bottom: 15px;
}

.sum, #order_sum, .deliverycosts, .commentContainer
{
	font-size:17px;
	font-weight:bold;
	text-align:right;
	color:#535353;
}

.customer label, .order_notice label
{
	padding-bottom:5px;
	display:block;
}
.customer select
{
	width:85%;
}
.customer input 
{
	width:80%;
	
}
.order_notice textarea
{
	width:90%;
	resize:none;
}

.customer .controls
{
	padding-bottom:12px;
}

#orderForm .sum
{
	margin-left: 0;
}
#orderForm .sum #order_sum
{
	margin-right: 5px;
}
/*
.order_submit .button
{
	cursor:pointer;
	width:50%;
	background-color: #A3D063;
   
   font-weight:bold;
   text-align:center;
    color: #FFFFFF;
    padding: 5px;
}*/

.order_submit button {
	margin-top: 10px;
}

.product > div {
	border-bottom: 1px solid black;
	padding-bottom: 15px;
}

#order {
	padding-top: 20px;
}



.product_image img {
	max-width: 100%;
	height: auto !important;
	width: auto !important;
}

.commentContainer {
font-size: 12px;
	}

	div .button {
		margin-top: 10px !important;
		background: $color-blue;
		color: white;
		text-align: center;
		padding: 10px;
		width: 300px;
	}


.sum, .deliverycosts, .commentContainer, #order_sum {
	color:inherit;
}

#main #full-content {
	background: none !important;
}

.span3 {
	float: left;
}

.span3 {
	width: 25%;
}


.clearfix:after {
    content: ".";
    clear: both;
    display: block;
    visibility: hidden;
    height: 0px;
}

textarea {
	height: 100px;
}

.nobold {
    font-weight: normal;
}

.controls a{
	color: #415837 !important;
}

.agb-checkbox input {
	width: auto;
}
.agb-checkbox label {
	display: inline-block;
	margin-left: 15px;
}
