.container-fluid,
.logo-container {
    max-width: 1170px;
    margin: 0 auto;
}

#order {
    margin-bottom: 30px;
}

.order_submit .button {
    width: 100%;
}

.order_notice textarea {
    width: 100%;
}

#order .controls input,
#order .controls select {
    width: 100%;
}

.logo-container-wrapper {
    text-align: center;
    background: $color-yellow;
}

img {
    max-width: 100%;
    height: auto;
}

.logo-bottom {
    position: relative;
    top: -20px;
    right: 15px;
    max-width: 60px;
}

.logo-top {
    width: 100%;
}

h2 {
    color: #000;
}

@media (max-width: 767px) {
    body {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .container-fluid,
    .logo-container {
        padding: 0 20px !important;
    }

    .logo-bottom {
        display: none;
    }
}

@media (max-width: 767px) {
    .logo-top {
        max-width: 200px;
    }
}
