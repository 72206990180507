@import "variables";
@import "vendors";

@import "form/order-form.scss";
@import "shop/shop.scss";


#app {
    .valid_error {
        color: $color-red;
    }

    .search-container {
        .search {
            margin-bottom: 15px;
        }
        .tag {
            background: $color-yellow;
            display: inline-block;
            margin: 0 5px 5px 0;
        }
        .search-input {
            @extend .form-control;
            width: 100%;
        }
    }

    #form-fields {
        @extend .row;
        width: auto;

        .span6 {
            @extend .col-sm-6;
            margin-left: 0 !important;

            input, textarea {
                @extend .form-control;
            }
        }
    }

    table.ordered-products {
        width: 100%;
        @extend .table;

        .btn-delete {
            background: $color-red;
            color: white;
        }
    }

    .product_price {
        text-align: center;
    }
}
